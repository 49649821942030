<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">信息资产管理软件</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP" style="text-indent: 2em">
        景翔拥有近20年的信息安全管理体系咨询经验，辅导过多家不同类型的生产型、服务型企业的信息安全管理体系搭建工作，对于信息资产的有效管理积累了丰富的经验。
        我们一直致力于为企业搭建围绕战略目标的治理体系，主张建立以核心业务为中心的管理模式，通过业务流程识别、展开、分解，再制定标准和责任人。通过对核心业务流程的拉通和优化，解决了传统体系与战略脱钩、流程滞后、全责不明、信息孤岛等问题。
      </p>
      <br />
      <br />
      <p class="PTitle LeftBor">解决客户的什么问题：</p>
      <p class="SmallP">
        易于掌握信息资产评级标准化
        <br />
        便于信息资产清单的维持、更新
      </p>
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/75.png"
        class="SonImgW"
      />
      <br />
      <br />
      <p class="PTitle">易于信息安全风险管制措施的维持、更新</p>
      <br />
      <p class="PTitle LeftBor">企业收益：</p>
      <div class="PList" style="margin-top: 10px">
        <p>1）易于进行经验传承</p>
        <p>
          2）完成了全核心业务流程的标准化梳理，为数字化转型提供了业务流程基础。
        </p>
        <p>
          3）由价值链带出瓶颈流程分析与改善，促成了研发与生产流程的改善专项；
        </p>
        <p>
          4）凭借价值链，公司流程管理体系成熟度进入价值贯通阶段，整体订单周期普遍缩短20%
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>